<template>
        <component :is="layout"></component>
</template>

<script>

import LayoutLogin from './views/LayoutLogin.vue'
import LayoutMain from './views/LayoutMain.vue'

export default {
  name: 'App',

  computed: {
    layout() {
      return 'Layout' + (this.$route.meta.layout || 'Main')
    }
  },

  components: {
    LayoutLogin, LayoutMain,
  },

  data: () => ({
    //
  }),
}
</script>

<style scoped>

/*>>> .v-navigation-drawer__content{
  color: #FFFFFF;
  background-color: #e31f1f;
}*/

/*
.ListItemClass {
  color: #e31f1f;
}
*/

.SelectedTile {
/*  border-radius: 4px;*/
/*  background-color: #ce7629;*/
  color: #000000;
}

.SelectedTile:hover {
  border-radius: 4px;
  background-color: #007bff;
  color: #FFFFFF !important;
}

.SelectedTile-active {
  border-radius: 4px;
  background-color: #007bff;
  color: #FFFFFF !important;
}
</style>
