import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: "",
    loggedIn: false,
    apiURL: '/lkapi',
//    apiURL: 'https://lk.internet.abakan.ru/lkapi',
//    apiURL: 'http://127.0.0.1:8005/lkapi',
//    wsURL: 'ws://127.0.0.1:8000/ws',
    wsURL: '',
    wsPROTO: 'ws',
    wsPORT: '9901',
    wsURI: '/ws',
//    payform: 'http://localhost:8005/lkapi/payform',
    payform: 'https://lk.internet.abakan.ru/lkapi/payform',
    login: '',
    token: '',
    acl: {},
    msgs: [],
    events: [],
  },
  mutations: {
    setTitle(state, title) {
      state.title = title
    },
    beforeLoggedIn(state, prm) {
      state.login = prm.login
    },
    loggedIn(state, prm) {
      state.loggedIn = true
      state.login = prm.login
      localStorage.tclk_login = prm.login
      state.token = prm.token
      localStorage.tclk_token = prm.token
      //console.log(window.location.href)
    },
    loggedOut(state) {
      state.loggedIn = false
      localStorage.removeItem('tclk_login')
      localStorage.removeItem('tclk_token')
      state.login = ''
      state.token = ''
    },
    wsURLMultihome(state, host) {
      //console.log('wsURL='+state.wsURL)
      if (state.wsURL == '') {
        state.wsURL = state.wsPROTO + '://' + host + ':' + state.wsPORT + state.wsURI
        localStorage.tclk_wsurl = state.wsURL
        //console.log('wsURL='+state.wsURL)
      }
    },
    wsURLStorage(state) {
      if (state.wsURL == '') {
        state.wsURL = localStorage.getItem('tclk_wsurl') ?? ''
      }
    },
    receiveMessage(state, msg) {
      if (state.msgs.length > 9) state.msgs.shift()
      state.msgs.push(msg)
    },
    receiveEvent(state, event) {
      if (state.events.length > 9) state.events.shift()
      state.events.push(event)
    },
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
