<template>
  <v-app id="inspire">
    <v-main>
      <v-container fill-height class="pa-4" style="max-width: 400px;">
        <v-row>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
    },
  }
</script>
