import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'Login', auth: false },
    component: () => import('../components/LoginForm.vue')
  },
  {
    path: '/main',
    name: 'Main',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/MainForm.vue')
  },
  {
    path: '/balance',
    name: 'Balance',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/BalanceForm.vue')
  },
  {
    path: '/services',
    name: 'Services',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/ServicesForm.vue')
  },
  {
    path: '/subscribes',
    name: 'Subscribes',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/SubscribesForm.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/StatisticsForm.vue')
  },
  {
    path: '/socials',
    name: 'Socials',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/SocialLinks.vue')
  },
  {
    path: '/blocking',
    name: 'Blocking',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/BlockingForm.vue')
  },
  {
    path: '*',
    redirect: '/login',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
//  console.log(store)
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.state.loggedIn) {
//      console.log(localStorage)
      if ((localStorage.getItem('tclk_login') !== null)&&(localStorage.getItem('tclk_token') !== null)) {
        store.commit('loggedIn', { login: localStorage.tclk_login, token: localStorage.tclk_token })
      }
    }
    if (store.state.loggedIn) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
